<template>
  <div>
    <AllPage ref="AllPage" :allProps="allProps">
      <template v-slot:limitT="scope">
        <template v-for="item in limitList">
          <el-tag
            :key="item.value"
            v-if="scope.data.limit.includes(item.value)"
            :type="item.type"
            size="mini"
            >{{ item.label }}</el-tag
          >
        </template>
      </template>
      <template v-slot:state="scope">
        <span
          v-for="item in stateList"
          :key="item.value"
          v-if="scope.data.state === item.value"
          >{{ item.label }}&nbsp;</span
        >
      </template>
      <template v-slot:operation="scope">
        <el-button
          size="small"
          @click.native.prevent="dialogForm('编辑', scope.data)"
        >
          编辑
        </el-button>
        <el-button
          size="small"
          @click.native.prevent="deleteRow(scope.data.id)"
        >
          删除
        </el-button>
      </template>
      <!-- <template v-solt>

      </template> -->
      <template v-slot:limit="scope">
        <el-checkbox-group
          v-model="scope.data.limit"
          @change="isShowOther(scope.data)"
        >
          <el-checkbox v-for="v in limitList" :key="v.value" :label="v.value"  :disabled="v.disabled || false">{{
            v.label
          }}</el-checkbox>
        </el-checkbox-group>
      </template>
 
    </AllPage>
  </div>
</template>
<script>
import { Api } from "@/apis";
import { allProps } from "./config";
import { messageBox } from "@/utils/messageBox";
export default {
  components: {
    AllPage: () => import("@/components/AllPage/AllPage.vue"),
  },
  provide() {
    return {
      changeRuleForm: this.changeRuleForm,
    };
  },
  data() {
    return {
      stateList: [
        { label: "正常", value: 0 },
        { label: "封停", value: 1 },
      ],
      limitList: [
        {
          value: "1",
          label: "超级管理员",
          type: "",
          disabled: true,
        },
        {
          value: "2",
          label: "管理员",
          type: "success",
        },
        {
          value: "3",
          label: "财务",
          type: "info",
        },
        {
          value: "4",
          label: "门店主",
          type: "warning",
        },
        {
          value: "5",
          label: "场地方",
          type: "danger",
        },
        {
          value: "6",
          label: "内容方",
          type: "danger",
        },
        {
          value: "7",
          label: "设备方",
          type: "danger",
        },
      ],
      allProps,
      Api,
      initProps: [],
    };
  },

  created() {
    this.initProps = [...this.allProps.items];
    this.allProps.items.forEach((item) => {
      if (item.prop === "dizhi") {
        item.options = this.$store.state.space;
      }else if(item.prop === "limit"){
        item.list=this.limitList
      } else if (item.prop === "state") {
        item.list = this.stateList
      }else if (item.prop === "owner_id") {
        item.list = res.data.data.map((v) => {
          return { label: v.name, value: v.id };
        });
      }
    });
  },
  methods: {
    isShowOther(data) {
      let { limit } = data;
      console.log(limit,"limit");
      this.$forceUpdate();
      let newAllProps = [...this.allProps.items];
      if (limit.includes("5") || limit.includes("6") || limit.includes("7")) {
        console.log();
        for (let i = 0; i < newAllProps.length; i++) {
          if (newAllProps[i].prop === "divide") {
            break;
          } else if (newAllProps[i].prop === "state") {
            this.allProps.items = this.initProps;
            this.allProps.items.splice(i, 0, {
              label: "卡号",
              prop: "card",
              type: ["add", "edit"],
            });
            this.allProps.items.splice(i, 0, {
              label: "银行",
              prop: "bank",
              type: ["add", "edit"],
            });
            this.allProps.items.splice(i, 0, {
              label: "分成",
              prop: "divide",
              type: ["add", "edit"],
            });
          }
        }
      } else {
        let formProp = ["card", "bank", "divide"];
        for (let i of formProp) {
         delete data[i] 
        }
        for (let i = 0; i < newAllProps.length; i++) {
          if (newAllProps[i].prop === "divide") {
            this.allProps.items.splice(i, 3);
          }
        }
        console.log(newAllProps.length);
      }
    },
    changeTableList(data) {
      data.forEach((item) => {
        item.limit = item.q_id.toString().split("");
        item.dname = item.md[0]?.name;
        item.dphone = item.md[0]?.phone;
        item.limitT = item.limit;
      });
    },
    dialogForm(title, row = {}) {
      allProps.dialog = {
        isShow: true,
        title,
      };
      row.isPaw = "0";
      row.ename =row.name
      allProps.data = { ...row };
    },
    changeRuleForm(data) {
      if (this.allProps.dialog.title == "新增") {
        [data.province, data.city] = data.dizhi;
        delete data.isPaw;
      }else if(this.allProps.dialog.title == "编辑"){
        data.q_id=Number(data.limit.join(""))
        data.phone=data.dphone
        // data.name=data.ename
        let {id,isPaw,ename,phone,q_id,state,token,name}=data
        console.log({id,isPaw,name,phone,q_id,state,token});
        return {id,isPaw,ename,phone,q_id,state,token,name}
      }
    },
    async deleteRow(id) {
      let result = await messageBox(Api.user.dele, { id });
      if (result) this.$refs.AllPage.getTableList();
    },
  },
};
</script>
<style lang="less" scoped>
.el-tag {
  margin-right: 5px;
}
</style>
