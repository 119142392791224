import { Api } from "@/apis";
export const allProps = {
  isShowBtn: {
    addBtn: true,
  },
  dialog: {
    isShow: false,
    title: "编辑",
  },
  data: { dizhi: [], isPaw: "0",limit:[], },
  items: [
    { label: "门店id", prop: "id", type: ["table"] },
    { label: "用户名", prop: "name", type: ["table"] },
    { label: "用户名", prop: "ename", type: ["add"] },
    { label: "账号密码", prop: "password", type: ["add"], Ftype: "password" },
    { label: "用户电话", prop: "phone", type: ["table", "add"] },
    { label: "店主名", prop: "dname", type: ["table", "add"] },
    { label: "店主电话", prop: "dphone", type: ["table", "add", "edit"] },
    { label: "身份证号", prop: "s_id", type: ["add"] },
    {
      label: "权限",
      prop: "limitT",
      type: ["table"],
      Ttype: "slot",
      list: [],
    },
    {
      label: "权限",
      prop: "limit",
      type: ["add", "edit"],
      Ftype: "slot",
      list: [],
    },
/*     {
      label: "卡号",
      prop: "card",
      type: ["add", "edit"],
      Ftype:"slot",
      isShow:"false"
    },
    {
      label: "银行",
      prop: "bank",
      type: ["add", "edit"],
      Ftype:"slot",
      isShow:"false"
    },
    {
      label: "分成",
      prop: "divide",
      type: ["add", "edit"],
      Ftype:"slot",
      isShow:"false"
    }, */
    { label: "状态", prop: "state", type: ["table", "edit"], Ttype: "slot",Ftype:"radio",list:[] },
    {
      label: "重置密码",
      prop: "isPaw",
      type: ["edit"],
      Ftype: "radio",
      list: [
        { value: "0", label: "否" },
        { value: "1", label: "是" },
      ],
    },
    {
      label: "性别",
      prop: "dsex",
      type: ["add"],
      Ftype: "radio",
      list: [
        { label: "男", value: 0 },
        { label: "女", value: 1 },
      ],
    },
    {
      label: "地址",
      prop: "dizhi",
      Ftype: "cascader",
      options: [],
      type: ["add"],
      filterable: true,
    },
    {
      label: "详细地址",
      prop: "address",
      Ftype: "textarea",
      type: ["add"],
    },
    {
      label: "操作",
      prop: "operation",
      Ttype: "slot",
      type: ["table"],
      width: "150px",
    },
  ],
  api: Api.user,
};
